<template>
  <div class="relative w-full">
    <input
      v-model="model"
      class="peer w-full rounded-md border border-gray-200 bg-white px-5 py-2 transition placeholder:text-gray-500 focus:border-primary focus:ring-4 focus:ring-primary/30 dark:border-neutral-800 dark:bg-neutral-950 dark:placeholder:text-neutral-500"
      placeholder=" "
      autocomplete="off"
      v-bind="$attrs"
    />
    <span
      v-if="label"
      class="pointer-events-none absolute -top-2 left-6 transform rounded bg-white px-1 text-xs text-gray-500 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:bg-transparent peer-placeholder-shown:px-0 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:peer-placeholder-shown:ml-1 dark:bg-neutral-950 dark:text-neutral-400 dark:peer-placeholder-shown:text-neutral-500"
    >
      {{ label }}
    </span>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  label?: string
}>()

defineOptions({
  inheritAttrs: false,
})

const model = defineModel<string | number | null>()
</script>
